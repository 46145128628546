import React from "react"

const SaviaLogo = props => {

  return (
    <svg
      className="w-full"
      id="logo"
      viewBox="0 0 1277 371"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >

      <path
        d="M722.184 360.476L852.66 13.2986H770.086L710.778 189.396C703.023 211.295 703.479 229.087 703.479 250.073C705.304 256.004 708.041 266.496 708.041 270.602C708.041 290.219 694.355 304.818 677.019 304.818C659.683 304.818 645.996 290.676 645.996 271.058C645.996 265.584 649.19 257.828 651.471 250.529C651.927 232.737 651.015 212.207 642.803 188.94L582.583 13.2986H500.465L630.941 360.476H722.184ZM852.66 360.476H928.848V13.2986H852.66V360.476Z"
      />
      <path
        d="M483.129 238.667H380.937L405.573 184.378C415.609 154.724 414.697 134.651 418.803 92.6796C419.259 85.8364 425.19 79.9055 432.033 79.9055C438.876 79.9055 444.807 85.3802 445.263 92.6796C449.369 133.739 447.544 153.356 458.493 184.378L483.129 238.667ZM604.937 360.476L475.829 13.2986H387.78L259.129 360.476H338.053L357.67 305.274H506.395L526.013 360.476H604.937Z"
      />
      <path
        d="M1154.67 238.667H1052.48L1077.12 184.378C1087.15 154.724 1086.24 134.651 1090.35 92.6796C1090.8 85.8364 1096.73 79.9055 1103.58 79.9055C1110.88 79.9055 1116.35 85.3802 1116.81 92.6796C1120.91 133.739 1119.09 153.356 1130.04 184.378L1154.67 238.667ZM1276.94 360.476L1147.37 13.2986H1059.32L930.672 360.476H1009.6L1029.21 305.274H1177.94L1197.56 360.476H1276.94Z"
      />
      <path
        d="M250.917 58.92C250.917 58.92 250.917 59.3762 250.917 58.92C251.829 60.7448 252.742 62.5696 253.654 63.9382L250.917 58.92ZM249.092 15.1237C228.562 5.99949 204.383 0.524724 177.01 0.0685124C98.0856 -1.30012 16.8799 20.5981 18.2485 104.997C19.1609 166.13 54.2893 198.065 138.232 212.663C192.522 222.7 207.12 233.193 206.664 260.566C206.208 287.026 183.397 306.643 146.9 306.187C101.735 305.275 70.7129 282.464 68.4318 240.492L0 252.81C2.73727 322.154 52.4644 369.144 141.882 370.969C224 372.338 282.395 328.085 283.764 256.916C284.676 196.696 257.76 162.936 187.959 149.706C122.265 137.845 94.4359 131.001 94.4359 99.9791C94.4359 69.8691 135.495 64.8508 171.536 65.3071C188.872 65.7633 203.014 66.2193 214.42 73.0624C212.139 79.9056 207.12 86.2926 206.208 91.3109C205.295 94.0482 204.839 96.7857 204.839 99.523C204.839 105.454 206.208 110.928 208.945 115.946C214.42 125.071 228.106 131.458 239.968 131.914C257.76 132.37 268.709 118.228 269.165 100.892C269.165 95.417 267.797 90.8548 265.515 86.2927C265.515 86.2927 259.129 75.7998 253.198 64.8507C252.285 63.482 251.373 61.6573 250.46 59.8325C250.46 59.8325 250.46 59.8323 250.46 59.3761C247.723 52.9891 246.811 45.6898 251.373 38.3904C256.391 28.81 255.935 21.9669 249.092 15.1237Z"
      />
    </svg>
  )
}

export default SaviaLogo
