import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const formContacto = () => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
      'bot-field': '',
      'form-name': 'contacto',
    }}
    onSubmit={(values, actions) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contacto', ...values }),
      })
        .then(() => {
          // alert('Success')
          actions.resetForm()
          const wrapper = document.querySelector('.formik-wrapper')
          const msgSuccess = document.querySelector('.form-msg-success')

          wrapper.classList.remove('flex')
          wrapper.classList.add('hidden')
          msgSuccess.classList.remove('hidden')
          msgSuccess.classList.add('flex')
        })
        .catch(() => {
          alert('Error')
        })
        .finally(() => actions.setSubmitting(false))
    }}
    validate={(values) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      const errors = {}
      if (!values.name) {
        errors.name = 'Campo obligatorio'
      }
      if (!values.email || !emailRegex.test(values.email)) {
        errors.email = 'Se requiere una email válido'
      }
      if (!values.message) {
        errors.message = 'Campo obligatorio'
      }
      return errors
    }}
  >
    {() => (
      <>
        <Form
          name='contacto'
          className='w-full text-left text-green formik-wrapper'
          data-netlify={true}
        >
          <div className='flex flex-col lg:space-x-10 lg:flex-row'>
            <p className='flex mb-12 lg:w-1/2'>
              <label className='flex-none w-16' htmlFor='name'>
                <Trans>Nombre</Trans>
              </label>
              <Field
                className='w-full mx-4 text-white bg-transparent border-b border-white'
                name='name'
              />
              <span className='text-xs bg-black error text-beige'>
                <ErrorMessage name='name' />
              </span>
            </p>

            <p className='flex mb-12 lg:w-1/2'>
              <label className='flex-none w-16' htmlFor='email'>
                Email
              </label>
              <Field
                className='w-full mx-4 text-white bg-transparent border-b border-white'
                name='email'
              />
              <span className='text-xs bg-black error text-beige'>
                <ErrorMessage name='email' />
              </span>
            </p>
          </div>
          <p className='flex mb-12 '>
            <label className='flex-none w-16' htmlFor='asunto'>
              <Trans>Asunto</Trans>
            </label>
            <Field
              className='w-full mx-4 text-white bg-transparent border-b border-white'
              name='asunto'
            />
            <span className='text-xs bg-black error text-beige'>
              <ErrorMessage name='name' />
            </span>
          </p>

          <p className='flex mb-12'>
            <label className='flex-none w-16' htmlFor='message'>
              <Trans>Mensaje</Trans>
            </label>
            <Field
              className='w-full mx-4 text-white bg-transparent border-b border-white'
              name='message'
              component='textarea'
            />
          </p>
          <span className='text-xs bg-black error text-beige'>
            <ErrorMessage name='message' />
          </span>

          <button
            className='block w-full px-6 py-2 mx-auto text-black uppercase rounded-full lg:max-w-max lg:inline-block bg-green'
            type='submit'
          >
            <Trans>Enviar</Trans>
          </button>
          <Field type='hidden' name='bot-field' />
          <Field type='hidden' name='form-name' />
        </Form>
        <div className='hidden form-msg-success'>
          <h4>
            <Trans>
              Tu mensaje se envió con éxito. Pronto nos pondremos en contacto.
            </Trans>
          </h4>
        </div>
      </>
    )}
  </Formik>
)

export default formContacto