import React, {useState, useEffect} from "react"

import { globalHistory } from '@reach/router'
import ScrollToTop from 'react-scroll-to-top'


// import parse from "html-react-parser"
import "../styles/tailwind.css"
import "../styles/typography.css"
import "../styles/global.css"
import Header from "../components/header"
import Footer from '../components/footer'

// import Scroll from "./locomotiveScroll"
// import "./locomotive-scroll.css"




const Layout = ({ isHomePage, children, location }) => {


  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setIsMenuOpen(false)

    })
  }, [setIsMenuOpen])

  return (
    <>
      <Header
        isMenuOpen={isMenuOpen}
        onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}
        className={location}
      ></Header>

      <div id='scroll-container'>
        <main>{children}</main>

        <Footer></Footer>
        <ScrollToTop
          top={200}
          smooth
          className={`fixed p-4 transition-opacity cursor-pointer duration-1000 rounded-full shadow opacity-100 focus:outline-none w-14 lg:w-16 h-14 lg:h-16 transform-gpu bottom-4 right-4 lg:bottom-8 lg:right-8 bg-green`}
        />
      </div>
    </>
  )
}

export default Layout
