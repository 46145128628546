import React, { useEffect, useRef, useState } from 'react'
// import { Link } from 'gatsby'
import { Link, useI18next, Trans } from 'gatsby-plugin-react-i18next'

import SaviaLogo from '../components/savia-logo'
import LogoCmpc from '../images/logo-cmpc.inline.svg'
import IcoIg from '../images/ico-rrss-instagram.inline.svg'
import IcoYt from '../images/ico-rrss-youtube.inline.svg'
import IcoTw from '../images/ico-rrss-twitter.inline.svg'
import IcoLi from '../images/ico-rrss-linkedin.inline.svg'
import { gsap } from 'gsap'
import { StaticImage } from 'gatsby-plugin-image'


import Marquee from "../components/marquee"



const Header = (props) => {

  const headerRef = useRef()
  const btnRef = useRef()
  const menuRef = useRef()


  const [menuTL] = useState(
    gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.3,
        // ease: 'expo.out'
      }
    })
  )

  useEffect(() => {
    // gsap.set(menuRef.current, {autoAlpha: 1})
    menuTL

      .fromTo(
        btnRef.current.firstChild,
        { marginBottom: '15px' },
        { marginBottom: 0 }
      )
      .fromTo(
        menuRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay:0.1}, 0
      )
      // .fromTo(headerRef.current, {scale:1}, {scale: 1.2})
      .reverse()

  }, [menuTL])

  useEffect(() => {
    menuTL.reversed(!props.isMenuOpen)
  }, [props.isMenuOpen, menuTL])

  const { languages, originalPath, language } = useI18next()




  return (
    <>
      <header
        ref={headerRef}
        data-scroll
        data-scroll-sticky
        data-scroll-target='#scroll-container'
        className={`transition-colors border-b duration-300 transform-gpu lg:h-auto fixed z-20 w-full text-black lg:py-12 global-header bg-beige ${
          props.isMenuOpen && 'menu-open'
        }`}
      >
        <div className='flex items-center justify-between h-full grid-cols-3 px-4 mx-auto lg:container'>
          <button
            ref={btnRef}
            className='flex flex-col items-center justify-center w-12 h-12 duration-1000 burger lg:hidden'
            onClick={props.onToggleMenu}
          >
            <span className='btn__line btn__line-top h-0.5 w-full   '></span>
            <span className='btn__line h-0.5 w-full '></span>
          </button>

          <Link
            className='flex items-center py-1 lg:mr-8 header-link-home'
            to='/'
          >
            <SaviaLogo className='flex-none h-6 pl-4 pr-2 mx-auto border-r fill-current lg:pr-4 lg:pl-0 header-link-home-svg lg:h-9 lg:ml-0 ' />
            <LogoCmpc className='flex-none h-8 pl-2 fill-current header-link-home-svg lg:pl-4 lg:h-10' />
          </Link>

          <div className='items-center hidden space-x-4 justify-self-end social lg:flex'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.instagram.com/somoscmpc/'
            >
              <IcoIg className='w-6 fill-current' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.youtube.com/channel/UC-zfhqFSpJTN0ZxsLHm_UvA/'
            >
              <IcoYt className='w-6 fill-current' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://twitter.com/SomosCMPC'
            >
              <IcoTw className='w-6 fill-current' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.linkedin.com/company/cmpc/'
            >
              <IcoLi className='w-6 fill-current' />
            </a>
          </div>

          <div className='hidden w-full px-10 mx-auto space-x-6 text-lg uppercase lg:flex'>
            <Link className='hover:underline' to='/about'>
              Savia
            </Link>

            <Link className='hover:underline' to='/cmpc'>
              CMPC
            </Link>

            <Link className='hover:underline' to='/noticias'>
              <Trans>Noticias</Trans>
            </Link>

            <Link className='hover:underline' to='/faq'>
              Q&A
            </Link>
          </div>

          <div className='items-center hidden pl-10 ml-auto border-l lg:flex'>
            <div className='flex items-center mr-12 lang space-x-7'>
              {languages.map((lng) => {
                return (
                  <Link
                    to={originalPath}
                    key={lng}
                    className={`leading-snug hover:underline ${
                      language === lng ? `border-b` : ``
                    }`}
                    language={lng}
                  >
                    {lng === 'en'
                      ? 'English'
                      : lng === 'es'
                      ? 'Español'
                      : lng === 'pt'
                      ? 'Português'
                      : ''}
                  </Link>
                )
              })}
            </div>
          </div>
          <a
            href='https:///app.saviapackaging.com/es/guia/'
            target='_blank'

            className='flex-none hidden px-8 py-2 text-center uppercase bg-black rounded-full btn--postula hover:bg-green hover:text-black lg:block text-green'
          >
            <Trans>Postula</Trans>
          </a>

          <a
            href='https:///app.saviapackaging.com/es/guia/'
            target='_blank'

            className='flex items-center justify-end h-full px-4 py-2 -mr-4 text-sm uppercase bg-black rounded-l-full lg:ml-auto btn--postula max-w-min lg:hidden w-52 text-green'
          >
            <Trans>Postula</Trans>
          </a>
        </div>
      </header>

      {/* <div className="mt-14 lg:mt-24"><Marquee /></div> */}


      <div
        ref={menuRef}
        className='fixed inset-0 z-10 flex flex-col items-center justify-between invisible w-screen h-screen pt-24 bg-black p-11 menu-mobile text-green'
      >
        <nav className='flex flex-col py-6 space-y-8 text-2xl text-center uppercase'>
          <Link className='hover:underline' to='/about'>
            Savia
          </Link>

          <Link className='hover:underline' to='/cmpc'>
            CMPC
          </Link>

          <Link className='hover:underline' to='/noticias'>
            <Trans>Noticias</Trans>
          </Link>

          <Link className='hover:underline' to='/faq'>
            Q&A
          </Link>
        </nav>

        <nav className='flex justify-around py-6 space-x-8 text-xl text-center border-t border-green'>
          {languages.map((lng) => {
            return (
              <Link
                to={originalPath}
                key={lng}
                className={`leading-snug hover:underline ${
                  language === lng ? `border-b` : ``
                }`}
                language={lng}
              >
                {lng === 'en'
                  ? 'English'
                  : lng === 'es'
                  ? 'Español'
                  : lng === 'pt'
                  ? 'Português'
                  : ''}
              </Link>
            )
          })}
        </nav>
        <div className='flex items-center justify-center w-full space-x-10 social'>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.instagram.com/somoscmpc/'
          >
            <IcoIg className='w-12 h-12 fill-current text-green' />
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.youtube.com/channel/UC-zfhqFSpJTN0ZxsLHm_UvA/'
          >
            <IcoYt className='w-12 h-12 fill-current text-green' />
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://twitter.com/SomosCMPC'
          >
            <IcoTw className='w-12 h-12 fill-current text-green' />
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.linkedin.com/company/cmpc/'
          >
            <IcoLi className='w-12 h-12 fill-current text-green' />
          </a>
        </div>

        <div className='flex items-end space-x-10 text-sm'>
          <StaticImage
            // className='w-full max-w-xs mb-4'
            // layout='fluid'

            // objectFit="contain"
            height={55}
            src='../images/logo-cmpc-green.png'
            alt='CMPC'
            className='flex-none '
          />
          <p className=''>
            <Trans>Programa de Packaging Sostenible</Trans>
          </p>
        </div>
      </div>
    </>
  )
  }

export default Header

