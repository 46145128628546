import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import {Link} from "gatsby"
import SaviaLogo from '../components/savia-logo'
import FormContacto from '../components/formContacto'
import IcoIg from '../images/ico-rrss-instagram.inline.svg'
import IcoYt from '../images/ico-rrss-youtube.inline.svg'
import IcoTw from '../images/ico-rrss-twitter.inline.svg'
import IcoLi from '../images/ico-rrss-linkedin.inline.svg'
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'

const Footer = () => {
  const { languages, originalPath, language } = useI18next()

  const trackDownload = () => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_category: 'download',
        event_label: 'download-bases',
      })
  }

  return (
    <>
      <footer className='bg-black'>
        <div className='grid overflow-hidden '>
          <StaticImage
            style={{
              gridArea: '1/1',
            }}
            layout='fullWidth'
            src='../images/bg-footer.jpg'
            alt=''
          />
          <div
            style={{
              gridArea: '1/1',
              position: 'relative',
            }}
            className='container flex flex-col justify-between px-4 py-12 mx-auto lg:flex-row'
          >
            <div className='flex flex-col justify-between max-w-sm mb-12 text-center lg:mb-0 lg:text-left'>
              <nav className='flex flex-col space-y-3 text-lg text-white underline uppercase mb-14'>
                <Link to='/'>
                  <Trans>Inicio</Trans>
                </Link>
                <Link to='/cmpc'>CMPC</Link>
                <Link to='/about'>Savia</Link>
                <Link to='/noticias'>
                  <Trans>Noticias</Trans>
                </Link>
                <Link to='/faq'>Q&A</Link>
              </nav>

              <div className='flex flex-col space-y-4'>
                <a
                  href='https:///app.saviapackaging.com/es/guia/'
                  target='_blank'
                  className='w-full px-6 py-2 text-center uppercase rounded-full bg-beige'
                >
                  <Trans>Postula</Trans>
                </a>
                <a
                  download
                  onClick={trackDownload}
                  href={`/assets/bases-${language}.pdf`}
                  className='w-full px-6 py-2 text-center uppercase rounded-full bg-green'
                >
                  <Trans>Descarga las bases</Trans>
                </a>
              </div>
            </div>

            <div className='w-full max-w-3xl text-green'>
              <h3 className='block mb-12 text-xl text-center underline uppercase lg:text-left'>
                <Trans>Contáctanos</Trans>
              </h3>

              <FormContacto />
            </div>
          </div>
        </div>

        <div className='container grid items-center w-full px-4 py-20 mx-auto gap-y-8 lg:grid-cols-3 text-green'>
          <div className='flex flex-col items-center justify-center row-start-3 lg:items-end lg:flex-row lg:row-start-auto lg:justify-start'>
            <p className='pb-4 text-sm lg:mr-4'>
              <Trans>Una iniciativa de</Trans>
            </p>
            <StaticImage
              // className='w-full max-w-xs mb-4'
              layout='fixed'
              height={65}
              src='../images/logo-cmpc-green.png'
              alt='CMPC'
            />
          </div>
          <div className='flex flex-col items-center text-sm font-extrabold'>
            <SaviaLogo className='mb-2 fill-current w-72' />
            <p>
              <Trans>Imaginando el futuro de nuestras fibras</Trans>
            </p>
          </div>

          <div className='flex items-center space-x-6 justify-self-center lg:justify-self-end social'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.instagram.com/somoscmpc/'
            >
              <IcoIg className='fill-current w-[30px] text-green' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.youtube.com/channel/UC-zfhqFSpJTN0ZxsLHm_UvA/'
            >
              <IcoYt className='fill-current w-[30px] text-green' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://twitter.com/SomosCMPC'
            >
              <IcoTw className='fill-current w-[30px] text-green' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.linkedin.com/company/cmpc/'
            >
              <IcoLi className='fill-current w-[30px] text-green' />
            </a>
          </div>
        </div>
      </footer>
      <div className='text-white bg-black border-t postFooter border-green'>
        <div className='container flex flex-col items-center justify-center px-4 py-8 mx-auto space-y-8 text-xl text-center lg:text-left lg:space-x-12 lg:space-y-0 lg:flex-row'>
          <p>Savia es un programa diseñado y ejecutado con el apoyo de:</p>

          <div className='flex items-end pb-2 space-x-6 logos'>
            <a target='_blank' rel='noreferrer' href='https://unit.la/'>
              <StaticImage
                // className='w-full max-w-xs mb-4'
                layout='fixed'
                height={30}
                src='../images/logo-unit.png'
                alt='unit'
              />
            </a>

            <span>+</span>

            <a target='_blank' rel='noreferrer' href='https://www.feedback.cl/'>
              <StaticImage
                // className='w-full max-w-xs mb-4'
                layout='fixed'
                height={25}
                src='../images/logo-feedback.png'
                alt='CMPC'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
